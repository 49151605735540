const serviceData = [
  {
    id: 1,
    title: 'Переоборудование',
    icon: 'ri-pencil-ruler-line',
    desc: 'Предлагаем уникальную возможность спроектировать и построить комфортабельный кемпер или автодом с учетом Ваших задач и потребностей. При этом автомобиль будет адаптирован стандартам эксплуатации, а также нашим климатическим условиям и дорогам.',
  },

  {
    id: 2,
    title: 'Дополнительное оборудование',
    icon: 'ri-briefcase-3-line',
    desc: 'Установка дополнительного оборудования в кемпер - это отличная возможность сделать свой автодом максимально комфортным, надежным и безопасным.',
  },

  {
    id: 3,
    title: 'Автомебель',
    icon: 'ri-home-6-line',
    desc: 'Установка кухонных модулей, модульной мебели, которая обеспечивает удобство в использовании оборудованием и свободный доступ к багажу и т.д.',
  },

  {
    id: 4,
    title: 'Диагностика',
    icon: 'ri-microscope-line',
    desc: 'Диагностика электронного и газового оборудования. Регулировка тормозной системы. Осмотр ходовой и корпуса на предмет затекания. Работа по Вашим спискам.',
  },

  {
    id: 5,
    title: 'Ремонт',
    icon: 'ri-hammer-line',
    desc: 'После проведения диагностики и обнаружения причины неисправности бортового оборудования, проводится квалифицированный ремонт любой сложности или замена. Установка пневмоподвески на караваны и автодома.',
  },

  {
    id: 6,
    title: 'Реставрация',
    icon: 'ri-tools-line',
    desc: 'От обнаружения и локальной замены затекающих участков корпуса и до масштабной реставрации пола, стен и крыши. Восстановление после ДТП.',
  },
];

export default serviceData;
